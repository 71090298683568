<template>
    <b-card
        no-body
        class="card-revenue-budget pt-1"
    >
        <b-row>
            <b-col cols="12">
                <div class="text-center">
                    <b-form-group>
                        <b-form-radio-group
                            class="d-none d-md-inline-flex w-75"
                            id="chart-buttons"
                            v-model="selectedFinanceType"
                            button-variant="outline-primary"
                            :options="chartTypes"
                            buttons
                            name="radios-btn-default"
                        />
                    </b-form-group>
                </div>
            </b-col>
        </b-row>

        <b-row class="pb-2 border-bottom">
            <b-col
                md="8"
                class="revenue-report-wrapper"
            >
                <div class="d-sm-flex justify-content-between align-items-center mb-3">
                    <h4 class="card-title mb-50 mb-sm-0">
                        {{i18nT(`Quote conversion report`)}}
                    </h4>

                    <div class="d-flex justify-content-between">
                        <transition-group name="fade" mode="out-in">

                        </transition-group>

                        <div class="d-flex align-items-center mr-2 font-small-3" v-if="selectedFinanceType == 1 || selectedFinanceType == -1">
                            <span class="bullet bullet-fixed  chart-color-1 mr-50" />
                            <span class="">{{i18nT(`Accepted`)}}</span>
                        </div>
                        <div class="d-flex align-items-center mr-2 font-small-3" v-if="selectedFinanceType == 2 || selectedFinanceType == -1">
                            <span class="bullet bullet-fixed chart-color-2 mr-50" />
                            <span>{{i18nT(`Pending`)}}</span>
                        </div>
                        <div class="d-flex align-items-center mr-2 font-small-3" v-if="selectedFinanceType == 3 || selectedFinanceType == -1">
                            <span class="bullet bullet-fixed chart-color-3 mr-50" />
                            <span>{{i18nT(`Rejected`)}}</span>
                        </div>
                    </div>
                </div>

                <!-- chart -->
                <vue-apex-charts
                    id="revenue-report-chart"
                    type="bar"
                    height="200"
                    :options="chartOptions"
                    :series="series"
                />
            </b-col>

            <b-col
                md="4"
                class="budget-wrapper"
            >
                <div class="d-flex justify-content-center align-content-center align-items-center">
                    <label for="year-select" class="mr-1 d-inline-block">{{i18nT(`Viewing data for year`)}}</label>
                    <b-form-select
                        id="year-select"
                        name="year-select"
                        class="w-auto my-2 d-inline-block"
                        v-model="selectedYear"
                        :options="years"
                    />
                </div>

                <div>
                    <h5>{{i18nT(`Accepted`)}}</h5>
                    <h2 class="mb-25 text-color-1 font-weight-bolder">
                        {{ {amount: acceptedTotal, currency: currency} | currency }}
                    </h2>
                </div>
                <div class="mt-2">
                    <h5>{{i18nT(`Pending`)}}</h5>
                    <h2 class="mb-25 text-color-2 font-weight-bolder">
                        {{ {amount: pendingTotal, currency: currency} | currency }}
                    </h2>
                </div>
                <div class="mt-2">
                    <h5>{{i18nT(`Rejected`)}}</h5>
                    <h2 class="mb-25 text-color-3 font-weight-bolder">
                        {{ {amount: rejectedTotal, currency: currency} | currency }}
                    </h2>
                </div>

            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormSelect,
    BFormGroup,
    BFormRadioGroup
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BFormGroup,
        BFormRadioGroup,
        VueApexCharts,
        BCard,
        BRow,
        BCol,
        //BFormGroup,
        BFormSelect
    },
    directives: {
        Ripple
    },
    data() {
        return {
            report: undefined,

            chartTypes: [
                {value: '-1', text: this.i18nT(`All`)},
                {value: '1', text: this.i18nT(`Accepted`)},
                {value: '2', text: this.i18nT(`Pending`)},
                {value: '3', text: this.i18nT(`Rejected`)},
            ],
            selectedFinanceType: '-1',

            selectedYear: -1,
            years: [2022],
            expenses: {},
            income: {},
            categories: [],
            currency: 'EUR',
        }
    },
    computed: {
        colors() {
            let colorSets = [];
            if (this.selectedFinanceType === '1')
                colorSets = [
                    '#28c77a',
                ]
            else if (this.selectedFinanceType === '2')
                colorSets = [
                    '#ff9f43',
                ]
            else if (this.selectedFinanceType === '3')
                colorSets = [
                    '#C21146',
                ]
            else
                colorSets = [
                    '#28c77a',
                    '#ff9f43',
                    '#C21146',
                ]
            return colorSets;
        },

        series() {
            let series = []
            if (this.report) {
                const rejected = []
                const accepted = []
                const pending = []

                for (const month in this.report.summary[this.selectedYear]) {
                    const d = this.report.summary[this.selectedYear][month]
                    rejected.push(d.rejected)
                    accepted.push(d.accepted)
                    pending.push(d.pending)
                }

                if (this.selectedFinanceType === '1')
                    series = [
                        { name: 'Accepted', data: accepted },
                    ]
                else if (this.selectedFinanceType === '2')
                    series = [
                        { name: 'Pending', data: pending },
                    ]
                else if (this.selectedFinanceType === '3')
                    series = [
                        { name: 'Rejected', data: rejected },
                    ]
                else
                    series = [
                        { name: 'Accepted', data: accepted },
                        { name: 'Pending', data: pending },
                        { name: 'Rejected', data: rejected },
                    ]
            }
            return series
        },

        chartOptions() {
            const categories = []
            let chartOptions = []
            let colors = this.colors;
            if (this.report) {
                for (const month in this.report.summary[this.selectedYear]) {
                    const d = this.report.summary[this.selectedYear][month]
                    categories.push(`${d.month_name}`)
                }
            }
            chartOptions = {
                chart: {
                    stacked: false,
                    type: 'bar',
                    toolbar: { show: false }
                },
                grid: {
                    padding: {
                        top: -20,
                        bottom: -10
                    },
                    yaxis: {
                        lines: { show: true }
                    }
                },
                xaxis: {
                    categories,
                    labels: {
                        style: {
                            colors: '#6E6B7B',
                            fontSize: '0.86rem',
                            fontFamily: 'Montserrat'
                        }
                    },
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    }
                },
                legend: {
                    show: false
                },
                dataLabels: {
                    enabled: false
                },
                colors,
                plotOptions: {
                    bar: {
                        //columnWidth: '20%',
                        endingShape: 'rounded'
                    },
                    distributed: true
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#6E6B7B',
                            fontSize: '0.86rem',
                            fontFamily: 'Montserrat'
                        }
                    }
                }
            }
            return chartOptions
        },

        acceptedTotal() {
            if (this.report) return this.report.total.total_accepted
            return 0
        },
        pendingTotal() {
            // if (this.report) return this.report.total.total_pending[this.selectedYear]
            if (this.report) return this.report.total.total_pending
            return 0
        },
        rejectedTotal() {
            if (this.report) return this.report.total.total_rejected
            return 0
        }
    },
    created() {
        this.$http.get(`quotes/summary`).then(({ data }) => {
            this.report = data.data
            this.currency = data.data.total.currency
            const years = []
            for (const year in data.data.summary) {
                years.push(year)
                this.selectedYear = year
            }
            this.years = years
        })
    },
    watch:{
        selectedFinanceType(val){
            const statusNames = {
                1: 'accepted',
                2: 'pending',
                3: 'rejected'
            };
            this.$emit('status-update', statusNames[val]);
        },
    }
}
</script>


<style>
.chart-color-1 {
    background-color: #28c77a;
}
.chart-color-2 {
    background-color: #ff9f43;
}
.chart-color-3 {
    background-color: #C21146;
}

.chart-color-5 {
    background-color: #79082A;
}

.text-color-1 {
    color: #28c77a;
}

.text-color-2 {
    color: #ff9f43;
}

.text-color-3 {
    color: #C21146;
}



.bullet-fixed {
    min-width: 1rem;

}

</style>
